<template>
  <div>
    <!-- Catalogue Ideas Table -->
    <catalogue-ideas-table />
    <!-- Catalogue Ideas Table -->
  </div>
</template>

<script>
import CatalogueIdeasTable from '@/views/Admin/Components/CatalogueIdeas/Components/CatalogueIdeasTable.vue'

export default {
  name: 'CatalogueIdeas',

  components: { CatalogueIdeasTable }
}
</script>
