<template>
  <v-dialog
    :value="visible"
    max-width="45rem"
    persistent
    @input="close"
  >
    <v-card>
      <v-card-title>{{ isEditMode && !isCopyMode ? 'Edit Tip' : 'Add Tip' }}</v-card-title>

      <v-divider />

      <v-card-text class="mt-5">
        <v-autocomplete
          v-model="form.type"
          label="Step"
          :items="tipTypes"
          item-text="label"
          item-value="value"
          clearable
          outlined
          dense
          hide-details="auto"
        />
      </v-card-text>

      <v-card-text>
        <catalogue-categories-dropdown
          v-model="form.catalogueIdeaId"
          :loading="loading.catalogueIdeas"
          clearable
        />
      </v-card-text>

      <v-card-text>
        <v-select
          v-model="form.lang"
          label="Language"
          :items="allowedLocales"
          outlined
          dense
          hide-details="auto"
        >
          <template #selection="data">
            <span>{{ data.item.toUpperCase() }}</span>
          </template>
          <template #item="data">
            <v-list-item-content v-text="data.item.toUpperCase()" />
          </template>
        </v-select>
      </v-card-text>

      <v-card-text>
        <v-text-field
          v-model="form.title"
          label="Title"
          outlined
          dense
          clearable
          hide-details="auto"
        />
      </v-card-text>

      <v-card-text>
        <VueTrix v-model="form.text" placeholder="Text" />
      </v-card-text>

      <v-divider />

      <v-card-actions class="justify-end">
        <v-btn
          text
          color="error"
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          :disabled="isSaveBtnDisabled"
          :loading="loading.saving"
          @click="isEditMode && !isCopyMode ? edit() : add()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CatalogueCategoriesDropdown from '@/views/Admin/Components/Common/CatalogueCategoriesDropdown.vue'
import VueTrix from 'vue-trix'
import _cloneDeep from 'lodash/cloneDeep'
import _isEmpty from 'lodash/isEmpty'
import { allowedLocales } from '@/options/localeOptions'
import tipTypes from '@/data/tipTypes'

import { mapActions } from 'vuex'

export default {
  name: 'DialogAddEditTip',

  components: {
    CatalogueCategoriesDropdown,
    VueTrix
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    tip: {
      type: Object,
      default: () => {}
    },
    isCopyMode: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      allowedLocales,
      loading: {
        catalogueIdeas: false,
        saving: false
      },
      form: {
        id: null,
        type: null,
        catalogueIdeaId: null,
        title: null,
        text: null,
        lang: allowedLocales[0]
      },
      tipTypes
    }
  },

  computed: {
    visible: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },

    isEditMode () {
      return !_isEmpty(this.tip)
    },

    isSaveBtnDisabled () {
      return !this.form.text || !this.form.type
    }
  },

  watch: {
    visible (value) {
      if (value && this.isEditMode) {
        this.setData()
      }
    }
  },

  async mounted () {
    await this.getCategories()
  },

  methods: {
    ...mapActions('admin', ['fetchCatalogueIdeas']),

    async add () {
      try {
        this.loading.saving = true
        await this.$http.post('admin/tips', this.form)
        this.loading.saving = false
        this.$emit('finished')
        this.close()
      } catch (e) {
        this.loading.saving = false
      }
    },

    async edit () {
      try {
        this.loading.saving = true
        await this.$http.put(`admin/tips/${this.form.id}`, this.form)
        this.loading.saving = false
        this.$emit('finished')
        this.close()
      } catch (e) {
        this.loading.saving = false
      }
    },

    async getCategories () {
      try {
        this.loading.catalogueIdeas = true
        await this.fetchCatalogueIdeas()
        this.loading.catalogueIdeas = false
      } catch (e) {
        this.loading.catalogueIdeas = false
      }
    },

    setData () {
      this.form = _cloneDeep(this.tip)
    },

    close () {
      setTimeout(() => {
        this.resetForm()
      }, 500)

      this.$emit('close')
    },

    resetForm () {
      this.form = {
        id: null,
        type: null,
        catalogueIdeaId: null,
        title: null,
        text: null,
        lang: allowedLocales[0]
      }
    }
  }
}
</script>
