<template>
  <v-dialog
    :value="visible"
    max-width="45rem"
    persistent
    @input="close"
  >
    <v-card
      :loading="loading.saving"
      :disabled="loading.saving"
    >
      <v-card-title>Edit Idea Template</v-card-title>

      <v-divider />

      <v-card-text class="mt-5">
        <catalogue-categories-dropdown
          v-model="form.catalogueIdeaId"
          :loading="loading.catalogueIdeas"
          clearable
        />
      </v-card-text>

      <v-card-text>
        <v-autocomplete
          v-model="form.ideaId"
          label="Idea"
          :items="ideas"
          :loading="loading.ideas"
          item-text="name"
          item-value="id"
          outlined
          dense
          clearable
          hide-details="auto"
        />
      </v-card-text>

      <v-card-text>
        <v-select
          v-model="form.lang"
          label="Language"
          :items="allowedLocales"
          outlined
          dense
          hide-details="auto"
        >
          <template #selection="data">
            <span>{{ data.item.toUpperCase() }}</span>
          </template>
          <template #item="data">
            <v-list-item-content v-text="data.item.toUpperCase()" />
          </template>
        </v-select>
      </v-card-text>

      <v-divider />

      <v-card-actions class="justify-end">
        <v-btn
          text
          color="error"
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          :elevation="0"
          :disabled="isSaveBtnDisabled"
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CatalogueCategoriesDropdown from '@/views/Admin/Components/Common/CatalogueCategoriesDropdown.vue'
import _isNull from 'lodash/isNull'
import _values from 'lodash/values'
import { allowedLocales } from '@/options/localeOptions'
import { mapActions } from 'vuex'

export default {
  name: 'DialogEditIdeaTemplate',

  components: { CatalogueCategoriesDropdown },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    ideaTemplate: {
      validator: value => typeof value === 'object' || value === null,
      required: true
    }
  },

  data () {
    return {
      allowedLocales,
      ideas: [],
      loading: {
        catalogueIdeas: false,
        ideas: false,
        saving: false
      },
      form: {
        id: null,
        catalogueIdeaId: null,
        ideaId: null,
        lang: allowedLocales[0]
      }
    }
  },

  computed: {
    visible: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },

    isSaveBtnDisabled () {
      return !!_values(this.form).some(value => _isNull(value))
    }
  },

  watch: {
    visible (value) {
      if (value) {
        this.setIdeaTemplate()
      }
    }
  },

  async mounted () {
    await Promise.all([this.getCategories(), this.getIdeas()])
  },

  methods: {
    ...mapActions('admin', ['fetchCatalogueIdeas']),

    async save () {
      try {
        this.loading.saving = true
        await this.$http.put(`admin/idea-templates/${this.form.id}`, this.form)
        this.$emit('finished')
        this.close()
        this.loading.saving = false
      } catch (e) {
        this.loading.saving = false
      }
    },

    setIdeaTemplate () {
      const { id, lang, catalogueIdeaId, ideaId } = this.ideaTemplate
      this.form = {
        id,
        catalogueIdeaId,
        ideaId,
        lang
      }
    },

    async getCategories () {
      try {
        this.loading.catalogueIdeas = true
        await this.fetchCatalogueIdeas()
        this.loading.catalogueIdeas = false
      } catch (e) {
        this.loading.catalogueIdeas = false
      }
    },

    async getIdeas () {
      try {
        this.loading.ideas = true
        const response = await this.$http.get('admin/idea-templates/template-manger-ideas')
        this.ideas = response.data.payload.ideas
        this.loading.ideas = false
      } catch (e) {
        this.loading.ideas = false
      }
    },

    close () {
      setTimeout(() => {
        this.resetForm()
      }, 500)

      this.$emit('close')
    },

    resetForm () {
      this.form = {
        id: null,
        catalogueIdeaId: null,
        ideaId: null,
        lang: allowedLocales[0]
      }
    }
  }
}
</script>
