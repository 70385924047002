<template>
  <div>
    <v-card
      :elevation="0"
      :loading="loading"
      :disabled="loading"
    >
      <v-card-title>
        Redeem coupon
      </v-card-title>

      <v-divider class="ma-0" />

      <v-card-text>
        <v-text-field
          v-model="form.code"
          outlined
          dense
          label="Coupon code"
          placeholder="Coupon code"
        />

        <v-text-field
          v-model="form.email"
          outlined
          dense
          label="Email"
          placeholder="Email"
        />
      </v-card-text>

      <v-divider />

      <v-card-actions class="d-flex justify-center pb-3">
        <v-btn
          color="primary"
          @click="submit"
        >
          Redeem
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'CouponsRedeem',

  data () {
    return {
      loading: false,
      form: {
        code: null,
        email: null
      }
    }
  },

  methods: {
    async submit () {
      try {
        this.loading = true
        await this.$http.put('admin/coupons/redeem', this.form)
        this.loading = false
        this.$notify({ title: 'Success', message: 'Successful coupon redemption', type: 'success' })
        this.resetForm()
        this.$emit('finished')
      } catch (error) {
        this.loading = false
      }
    },

    resetForm () {
      this.form = {
        code: null,
        email: null
      }
    }
  }
}
</script>
