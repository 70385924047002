<template>
  <v-autocomplete
    v-model="content"
    label="Idea type"
    item-value="id"
    :item-text="item => item.name"
    :items="catalogueOptionsFormatted"
    :loading="loading"
    :clearable="clearable"
    :background-color="backgroundColor"
    outlined
    dense
    hide-details="auto"
  />
</template>

<script>
import _sortBy from 'lodash/sortBy'
import { mapGetters } from 'vuex'

export default {
  name: 'CatalogueCategoriesDropdown',

  props: {
    value: {
      required: true,
      validator: value => typeof value === 'number' || value === null,
      default: null
    },

    loading: {
      type: Boolean,
      default: false
    },

    clearable: {
      type: Boolean,
      default: false
    },

    backgroundColor: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapGetters('admin', ['getCatalogueIdeas']),

    content: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },

    catalogueOptionsFormatted () {
      const items = []

      if (this.getCatalogueIdeas) {
        const getCatalogueIdeas = _sortBy(this.getCatalogueIdeas, ['position'])

        getCatalogueIdeas.forEach(item => {
          items.push({ header: item.name })

          for (const index of Object.keys(item.children)) {
            items.push(item.children[index])
          }
        })
      }

      return items
    }
  }
}
</script>
