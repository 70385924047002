export default [
  {
    value: 'idea',
    label: 'Idea'
  },
  {
    value: 'product',
    label: 'Product'
  },
  {
    value: 'promotion',
    label: 'Promotion'
  },
  {
    value: 'distribution',
    label: 'Distribution'
  },
  {
    value: 'partner',
    label: 'Partners'
  },
  {
    value: 'market',
    label: 'Market'
  },
  {
    value: 'customers',
    label: 'Customers'
  },
  {
    value: 'competitors',
    label: 'Competitors'
  },
  {
    value: 'swot-analysis',
    label: 'SWOT Analysis'
  },
  {
    value: 'market-potential',
    label: 'Market Potential'
  },
  {
    value: 'startup-cost',
    label: 'Startup costs'
  },
  {
    value: 'financing',
    label: 'Financing'
  },
  {
    value: 'managementTeam',
    label: 'Management Team'
  },
  {
    value: 'staffAndPayroll',
    label: 'Staff & Payroll'
  },
  {
    value: 'ownership',
    label: 'Ownership'
  },
  {
    value: 'revenue-stream',
    label: 'Revenue Streams'
  },
  {
    value: 'direct-costs',
    label: 'Direct Costs'
  },
  {
    value: 'marketing-budget',
    label: 'Marketing Budget'
  },
  {
    value: 'other-overheads',
    label: 'Other Overheads'
  },
  {
    value: 'profit-and-cash-flow',
    label: 'Profit & Cash Flow'
  }
]
