<template>
  <div>
    <!-- Form -->
    <el-form>
      <h4>Change subscription</h4>

      <el-row :gutter="15" class="mb-3">
        <el-col :xl="6" class="mb-3">
          <el-input
            v-model="form.email"
            placeholder="Email"
            type="email"
            class="w-100"
            maxlength="90"
          />
        </el-col>

        <el-col :sm="12" :xl="6" class="mb-3">
          <el-select v-model="form.plan" placeholder="Plan" class="w-100" @change="setEndDate">
            <el-option
              v-for="item in userPlans"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-col>

        <el-col :sm="12" :xl="6" class="mb-3">
          <el-date-picker
            v-model="form.date"
            class="w-100"
            type="date"
            placeholder="End date"
            value-format="yyyy-MM-dd"
          />
        </el-col>
      </el-row>
    </el-form>
    <!-- /Form -->

    <el-row>
      <el-col>
        <el-button class="small mt-3 mb-3" :loading="saving" :disabled="false" @click="onSave">
          Save
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import userPlans from '@/data/userPlans'

export default {
  name: 'UserSubscriptions',

  data () {
    return {
      saving: false,
      form: {
        email: '',
        plan: null,
        date: ''
      },
      userPlans
    }
  },

  methods: {
    onSave () {
      this.saving = true

      return this.$http.post('admin/users/subscription', this.form)
        .then((response) => {
          if (response.status === 200) {
            this.$notify({ title: 'Success', message: 'User subscription added successfully', type: 'success' })
            this.resetForm()
          }
        }).finally(() => {
          this.saving = false
        })
    },
    setEndDate (plan) {
      if ([21, 22, 23].includes(plan)) {
        const aYearFromNow = new Date()
        aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 99)
        this.form.date = aYearFromNow.toISOString().substring(0, 10)

        return this.form.date
      }

      this.form.date = ''
    },
    resetForm () {
      this.form = {
        email: '',
        plan: null,
        date: ''
      }
    }
  }
}
</script>
