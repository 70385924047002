<template>
  <div>
    <div class="d-flex align-center justify-content-between">
      <!-- Title -->
      <div>
        <h4>Tips</h4>
      </div>
      <!-- Title -->

      <div>
        <v-btn
          color="primary"
          :elevation="0"
          @click="dialogAddTipVisible = true"
        >
          Add Tip
        </v-btn>
      </div>
    </div>

    <!-- Tips Table -->
    <tips-table ref="tipsTable" />
    <!-- /Tips Table -->

    <!-- Dialog Add Tip -->
    <dialog-add-edit-tip
      v-model="dialogAddTipVisible"
      @finished="$refs.tipsTable.getTips()"
      @close="dialogAddTipVisible = false"
    />
    <!-- /Dialog Add Tip -->
  </div>
</template>

<script>
import DialogAddEditTip from '@/views/Admin/Components/Tips/Components/Dialogs/DialogAddEditTip.vue'
import TipsTable from '@/views/Admin/Components/Tips/Components/TipsTable.vue'

export default {
  name: 'Tips',

  components: { DialogAddEditTip, TipsTable },

  data () {
    return {
      dialogAddTipVisible: false
    }
  }
}
</script>
