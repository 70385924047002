<template>
  <div id="idea-home">
    <!-- Content -->
    <div class="container-1200">
      <div class="content">

        <!-- Header -->
        <header>
          <h1>Admin panel</h1>

          <ib-divider class="mt-2" block />
        </header>
        <!-- /Header -->

        <!-- Height Holder For Sticky Header -->
        <div class="height-holder d-none d-md-block" />
        <!-- /Height Holder For Sticky Header -->

        <!-- Content Wrapper -->
        <v-app>
          <div class="content-wrapper">
            <el-tabs type="card">
              <el-tab-pane label="Guide Examples" lazy>
                <guide-examples />
              </el-tab-pane>
              <el-tab-pane label="User Subscriptions" lazy>
                <user-subscriptions />
                <ib-divider block />
                <users-list />
              </el-tab-pane>
              <el-tab-pane label="Coupons" lazy>
                <coupons-redeem
                  class="mt-4 mb-6"
                  @finished="onRedemptionFinished"
                />
                <coupons-list ref="couponsList" />
              </el-tab-pane>
              <el-tab-pane label="Templates" lazy>
                <templates />
              </el-tab-pane>
              <el-tab-pane label="Catalogue Categories" lazy>
                <catalogue-categories />
              </el-tab-pane>
              <el-tab-pane label="Catalogue Ideas" lazy>
                <catalogue-ideas />
              </el-tab-pane>
              <el-tab-pane label="Tips" lazy>
                <tips />
              </el-tab-pane>
            </el-tabs>
          </div>
        </v-app>
        <!-- /Content Wrapper -->
      </div>
    </div>
    <!-- /Content -->
  </div>
</template>

<script>
import CatalogueCategories from '@/views/Admin/Components/CatalogueCategories/CatalogueCategories.vue'
import CatalogueIdeas from '@/views/Admin/Components/CatalogueIdeas/CatalogueIdeas.vue'
import CouponsList from '@/views/Admin/Components/CouponsList'
import CouponsRedeem from '@/views/Admin/Components/CouponsRedeem'
import GuideExamples from '@/views/Admin/Components/GuideExamples'
import Templates from '@/views/Admin/Components/Templates/Templates.vue'
import Tips from '@/views/Admin/Components/Tips/Tips.vue'
import UserSubscriptions from '@/views/Admin/Components/UserSubscriptions'
import UsersList from '@/views/Admin/Components/UsersList'

export default {
  name: 'AdminHome',

  components: {
    CatalogueIdeas,
    CatalogueCategories,
    Templates,
    Tips,
    CouponsList,
    CouponsRedeem,
    UsersList,
    UserSubscriptions,
    GuideExamples
  },

  data () {
    return {
    }
  },

  computed: {
    isTemplateAdmin () {
      return this.$store.getters['user/isTemplateAdmin']
    }
  },

  created () {
    if (!this.isTemplateAdmin) {
      this.$router.push({ name: 'home' })
    }
  },

  methods: {
    onRedemptionFinished () {
      this.$refs.couponsList.getCoupons()
    }
  }
}
</script>

<style>
#idea-home {
  margin-left: 0;
}
.image-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  height: 38px;
  margin: auto;
  vertical-align: middle;
  text-align: center;
}
.image-uploader:hover {
  border-color: #409eff;
}
.el-upload {
  line-height: 38px;
  width: 100%;
}
.image {
  height: 38px;
}
</style>
