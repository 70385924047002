<template>
  <div class="admin-users-list">
    <div class="d-md-flex justify-content-between mt-3 mb-2">
      <h4 class="my-auto">
        User base <i v-if="loading" class="el-icon-loading ml-1" />
      </h4>
      <div class="d-flex">
        <el-input
          v-model="params.query"
          placeholder="Search by email/name"
          class="my-auto mr-3"
          prefix-icon="el-icon-search"
          @change="onSearch"
        />
        <v-btn
          color="primary"
          :loading="exporting"
          class="px-5"
          @click="exportUsers"
        >
          Export
        </v-btn>
      </div>
    </div>

    <el-table
      :data="tableData"
      class="table-normalize w-100"
    >
      <el-table-column
        prop="id"
        label="User ID"
        width="80"
      />
      <el-table-column
        prop="email"
        label="Email address"
      />
      <el-table-column
        prop="name"
        :label="$t('fields.name')"
      />
      <el-table-column
        prop="plan"
        label="Plan"
        width="120"
      />
      <el-table-column
        prop="created"
        label="Created"
        width="120"
      />
      <el-table-column
        prop="validDate"
        label="Valid until"
        width="120"
      />
      <el-table-column
        prop="paddle"
        label="Paddle ID"
        width="120"
      />
      <el-table-column
        prop="status"
        label="Status"
        width="120"
      />
      <el-table-column
        fixed="right"
        label="Actions"
        width="100"
      >
        <template slot-scope="scope">
          <template v-if="tableData[scope.$index].isVerified === false">
            <el-button
              type="text"
              size="small"
              @click.native.prevent="verify(scope.$index)"
            >
              Verify
            </el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <el-row
      v-if="pagination && pagination.total > 0"
      class="pagination-wrapper p-3"
    >
      <h6 class="text-silver my-0 text-right">
        Showing {{ pagination.from }}-{{ pagination.to }} of total {{ pagination.total }} results
      </h6>
      <el-pagination
        class="text-center"
        :page-size="pagination.size"
        :pager-count="11"
        layout="prev, pager, next"
        :total="pagination.total"
        :disabled="loading"
        @current-change="paginationChange"
      />
    </el-row>
  </div>
</template>

<script>
import axios from 'axios'

const CancelToken = axios.CancelToken
let source = null

export default {
  name: 'UsersList',

  data () {
    return {
      loading: false,
      exporting: false,
      params: {
        page: 1,
        query: ''
      },
      tableData: [],
      pagination: []
    }
  },

  created () {
    this.getUsers()
  },

  methods: {
    onSearch () {
      this.params.page = 1
      this.getUsers()
    },
    paginationChange (value) {
      this.params.page = value
      this.getUsers()
    },
    async getUsers () {
      if (source) {
        await source.cancel()
      }
      source = CancelToken.source()

      this.loading = true
      this.$http.get('admin/users', { params: this.params, cancelToken: source.token })
        .then(response => {
          const { data, pagination } = response.data.payload

          this.tableData = data
          this.pagination = pagination
        }).finally(() => {
          this.loading = false
        })
    },
    exportUsers () {
      this.exporting = true
      this.$http.get('admin/users/export', { params: { query: this.params.query } })
        .then(response => {
          // Force Download
          window.open(response.data.payload.url)
        }).finally(() => {
          this.exporting = false
        })
    },
    verify (index) {
      const user = this.tableData[index]

      this.$http.post('admin/users/verification', {
        email: user.email,
        userId: user.id,
        verified: true
      })
        .then(_ => {
          this.$notify({
            title: 'Success',
            message: `User with email ${user.email} is successfully verified.`,
            type: 'success'
          })
          user.isVerified = true
        })
    }
  }
}
</script>

<style scoped lang="scss">
.admin-users-list {
  ::v-deep {
    .el-table th.el-table__cell {
      background-color: $color-text-pale-blue;
    }
  }

  .pagination-wrapper {
    background-color: $color-white;
    border-top: 1px solid #EBEEF5;
  }
}
</style>
