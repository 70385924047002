<template>
  <div>
    <div class="d-flex align-center justify-content-between mb-3">
      <div>
        <h4>Templates</h4>
      </div>
      <div>
        <v-btn
          color="primary"
          :elevation="0"
          @click="dialogAddIdeaTemplateVisible = true"
        >
          Add Template
        </v-btn>
      </div>
    </div>

    <!-- Idea Templates List -->
    <idea-templates-list ref="ideaTemplatesList" />
    <!-- Idea Templates List -->

    <!-- Dialog Add Idea Template -->
    <dialog-add-idea-template
      v-model="dialogAddIdeaTemplateVisible"
      @finished="onCreateTemplateFinished"
      @close="dialogAddIdeaTemplateVisible = false"
    />
    <!-- Dialog Add Idea Template -->
  </div>
</template>

<script>
import DialogAddIdeaTemplate from '@/views/Admin/Components/Templates/Components/Dialogs/DialogAddIdeaTemplate.vue'
import IdeaTemplatesList from '@/views/Admin/Components/Templates/Components/IdeaTemplatesList.vue'

export default {
  name: 'Templates',

  components: { IdeaTemplatesList, DialogAddIdeaTemplate },

  data () {
    return {
      dialogAddIdeaTemplateVisible: false
    }
  },

  methods: {
    onCreateTemplateFinished () {
      this.$refs.ideaTemplatesList.getIdeaTemplates()
    }
  }
}
</script>
