<template>
  <div>
    <!-- Form -->
    <el-form>
      <h4>Add custom guide examples</h4>

      <el-row :gutter="15" class="mb-3">
        <el-col :sm="17" :xl="8" class="mb-3">
          <el-input
            v-model="examples.title"
            placeholder="Title"
            class="w-100"
            maxlength="90"
          />
        </el-col>

        <el-col :sm="7" :xl="8" class="mb-3">
          <el-select v-model="examples.type" placeholder="Step" class="w-100">
            <el-option
              v-for="item in guideExamplesTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-col>

        <el-col :sm="12" :xl="8" class="mb-3">
          <el-select v-model="examples.lang" placeholder="Language" class="w-100">
            <el-option
              v-for="locale in allowedLocales"
              :key="locale"
              :label="locale.toUpperCase()"
              :value="locale"
            />
          </el-select>
        </el-col>

        <el-col :sm="12" :xl="8" class="mb-3">
          <catalogue-categories-dropdown
            v-model="examples.catalogueIdeaId"
            background-color="#fff"
            clearable
          />
        </el-col>

        <el-col :sm="24" :xl="8" class="mb-3">
          <el-upload
            class="image-uploader"
            action="/api/v1/upload"
            :show-file-list="false"
            :on-success="handleImageSuccess"
            :before-upload="beforeImageUpload"
          >
            <img v-if="examples.imagePreview" :src="examples.imagePreview" alt="" class="image">
            <i v-else class="el-icon-plus avatar-uploader-icon" />
          </el-upload>
        </el-col>
      </el-row>

      <div class="trix-wrapper">
        <VueTrix v-model="examples.text" placeholder="Enter content" />
      </div>

    </el-form>
    <!-- /Form -->
    <el-button class="small mt-3 mb-3" :loading="examplesLoading" :disabled="false" @click="onGuideExamplesSave">
      Save
    </el-button>
    <span
      v-if="examples && examples.id"
      class="ml-3 cursor-pointer"
      @click="resetForm"
    >
      Cancel editing
    </span>

    <v-app>
      <div>
        <div class="d-flex justify-content-between flex-column flex-md-row align-md-center mb-4 mb-md-0">
          <h4 class="mb-4 mb-md-8">
            Guide Examples
          </h4>
          <div>
            <v-text-field
              v-model="searchValue"
              outlined
              dense
              placeholder="Search by Idea"
              hide-details
              clearable
            >
              <template #prepend-inner>
                <v-icon>{{ icons.mdiMagnify }}</v-icon>
              </template>
            </v-text-field>
          </div>
        </div>

        <v-tabs v-model="tab">
          <v-tabs-slider color="primary" />

          <v-tab
            v-for="locale in allowedLocales"
            :key="locale"
          >
            {{ locale }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="locale in allowedLocales"
            :key="locale"
          >
            <v-card>
              <v-data-table
                :items="searchResult"
                :loading="!fetched.examples"
                hide-default-footer
                :headers="headers"
                :options="{
                  itemsPerPage: 1000
                }"
              >
                <template #[`item.catalogueIdea`]="{item}">
                  {{ item.catalogueIdea ? item.catalogueIdea : '-' }}
                </template>
                <template #[`item.catalogueCategory`]="{item}">
                  {{ item.catalogueCategory ? item.catalogueCategory : '-' }}
                </template>
                <template #[`item.actions`]="{item}">
                  <v-btn
                    color="primary"
                    small
                    text
                    @click="onClickEdit(item.id)"
                  >
                    Edit
                  </v-btn>
                  <v-btn
                    color="error"
                    small
                    text
                    @click="onClickDelete(item.id)"
                  >
                    Delete
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-app>
  </div>
</template>

<script>
import { allowedLocales } from '@/options/localeOptions'

import CatalogueCategoriesDropdown from '@/views/Admin/Components/Common/CatalogueCategoriesDropdown.vue'
import VueTrix from 'vue-trix'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _findIndex from 'lodash/findIndex'
import guideExamplesTypes from '@/data/guideExamplesTypes'
import { mapActions } from 'vuex'
import { mdiMagnify } from '@mdi/js'

export default {
  name: 'GuideExamples',

  components: {
    CatalogueCategoriesDropdown,
    VueTrix
  },

  data () {
    return {
      tab: null,
      searchValue: '',
      allowedLocales,
      examplesLoading: false,
      guideExamples: [],
      guideExamplesTable: [],
      fetched: {
        ideas: false,
        examples: false
      },
      examples: {
        id: null,
        type: '',
        title: '',
        text: '',
        image: null,
        imagePreview: null,
        catalogueIdeaId: null,
        lang: null
      },
      guideExamplesTypes,
      icons: { mdiMagnify }
    }
  },

  computed: {
    selectedLocale () {
      return allowedLocales[this.tab]
    },

    guides () {
      return this.guideExamplesTable.length ? _filter(this.guideExamplesTable, ['lang', this.selectedLocale]) : []
    },

    searchResult () {
      const searchData = this.guideExamplesTable.filter(item => item.catalogueIdea !== null)

      if (this.searchValue !== '' && this.searchValue) {
        if (searchData.length) {
          return searchData.filter(item => item.catalogueIdea.toUpperCase().includes(this.searchValue.toUpperCase()) && item.lang === this.selectedLocale)
        }

        return []
      }

      return this.guides
    },

    headers () {
      return [
        {
          text: 'Title',
          value: 'title',
          divider: false,
          sortable: false
        },
        {
          text: 'Type',
          value: 'type',
          divider: false,
          sortable: false
        },
        {
          text: 'Category',
          value: 'catalogueCategory',
          align: 'center',
          divider: false,
          sortable: false
        },
        {
          text: 'Idea',
          value: 'catalogueIdea',
          align: 'center',
          divider: false,
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          divider: false,
          sortable: false
        }
      ]
    }
  },

  created () {
    this.getIdeas()
    this.getExamples()
  },

  methods: {
    ...mapActions('admin', ['fetchCatalogueIdeas']),

    onGuideExamplesSave () {
      this.examplesLoading = true
      if (this.examples && this.examples.id) {
        return this.$http.put(`admin/guide-examples/${this.examples.id}`, this.examples)
          .then((response) => {
            if (response.status === 200) {
              this.$notify({ title: 'Success', message: 'Guide example is updated successfully', type: 'success' })
              this.getExamples()
              this.resetForm()
            }
          }).finally(() => {
            this.examplesLoading = false
          })
      }

      this.$http.post('admin/guide-examples', this.examples)
        .then((response) => {
          if (response.status === 200) {
            this.$notify({ title: 'Success', message: 'Guide example is added successfully', type: 'success' })
            this.getExamples()
            this.resetForm()
          }
        }).finally(() => {
          this.examplesLoading = false
        })
    },

    onClickEdit (guideExampleId) {
      const editingExample = _find(this.guideExamples, ['id', guideExampleId])
      this.examples = {
        id: editingExample.id,
        type: editingExample.type,
        title: editingExample.title,
        text: editingExample.text,
        image: null,
        imagePreview: editingExample.image ? editingExample.image.path + editingExample.image.filename : null,
        catalogueIdeaId: editingExample.catalogueIdea ? editingExample.catalogueIdea.id : null,
        lang: editingExample.lang
      }
    },

    onClickDelete (guideExampleId) {
      if (confirm('Are you sure?')) {
        const editingExample = _find(this.guideExamples, ['id', guideExampleId])
        const index = _findIndex(this.guideExamples, ['id', editingExample.id])

        this.$http.delete(`admin/guide-examples/${editingExample.id}`)
          .then(() => {
            this.guideExamples.splice(index, 1)
            this.guideExamplesTable.splice(index, 1)
          })
      }
    },

    async getIdeas () {
      try {
        await this.fetchCatalogueIdeas()
        this.fetched.ideas = true
      } catch (e) {
        this.fetched.ideas = true
      }
    },

    getExamples () {
      this.$http.get('admin/guide-examples')
        .then(response => {
          this.guideExamples = response.data.payload.examples
          this.guideExamplesTable = response.data.payload.examples.map((item) => {
            return {
              id: item.id,
              title: item.title,
              type: item.type,
              catalogueCategory: item.catalogueCategory ? item.catalogueCategory.name : null,
              catalogueIdea: item.catalogueIdea ? item.catalogueIdea.name : null,
              lang: item.lang
            }
          })
          setTimeout(() => {
            this.fetched.examples = true
          }, 500)
        }).catch(() => {
          this.fetched.examples = true
        })
    },

    resetForm () {
      this.examples = {
        type: '',
        title: '',
        text: '',
        image: null,
        imagePreview: null,
        catalogueIdeaId: null,
        lang: null
      }
    },

    beforeImageUpload (file) {
      const isAcceptedFormat = file.type === 'image/jpeg' || file.type === 'image/png'
      const isImageSizeLowerThen5M = file.size / 1024 / 1024 <= 5

      if (!isAcceptedFormat) {
        // this.$message.error('Picture must be JPG or PNG format!')
        alert('Picture must be JPEG or PNG format!')
      }
      if (!isImageSizeLowerThen5M) {
        // this.$message.error('Picture size can not exceed 5MB!')
        alert('Picture size can not exceed 5MB!')
      }

      return isAcceptedFormat && isImageSizeLowerThen5M
    },

    handleImageSuccess (result, file) {
      this.examples.image = result.payload.filename
      this.examples.imagePreview = URL.createObjectURL(file.raw)
    }
  }
}
</script>

<style scoped lang="scss">
  .trix-wrapper {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
  }
</style>
