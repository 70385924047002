<template>
  <div>
    <div class="d-flex align-center justify-content-between">
      <!-- Title -->
      <div>
        <h4>Catalogue Ideas</h4>
      </div>
      <!-- Title -->

      <!-- Search -->
      <div>
        <v-text-field
          v-model="searchValue"
          outlined
          dense
          placeholder="Search by Name"
          hide-details
          clearable
        >
          <template #prepend-inner>
            <v-icon>{{ icons.mdiMagnify }}</v-icon>
          </template>
        </v-text-field>
      </div>
    </div>
    <!-- Search -->
    <!-- Table -->
    <v-data-table
      :loading="loading"
      :items="searchResult"
      :headers="headers"
      :options="{
        itemsPerPage: 15
      }"
    >
      <template #[`item.infoName`]="{item}">
        <v-tooltip top :disabled="loading">
          <template #activator="{ on, attrs }">
            <v-icon
              color="primary"
              dark
              v-bind="attrs"
              class="cursor-pointer"
              v-on="on"
            >
              {{ icons.mdiInformationOutline }}
            </v-icon>
          </template>
          <span>
            <span
              v-for="(option, key) in item.options"
              :key="`${key}-${option.name}-${Math.random()}`"
            >
              {{ key.toUpperCase() }} - {{ option.name }} <br>
            </span>
          </span>
        </v-tooltip>
      </template>
      <template #[`item.infoDescription`]="{item}">
        <v-tooltip top :disabled="loading">
          <template #activator="{ on, attrs }">
            <v-icon
              color="primary"
              dark
              v-bind="attrs"
              class="cursor-pointer"
              v-on="on"
            >
              {{ icons.mdiInformationOutline }}
            </v-icon>
          </template>
          <span>
            <span
              v-for="(option, key) in item.options"
              :key="`${key}-${option.name}-${Math.random()}`"
            >
              {{ key.toUpperCase() }} - {{ `${option.description.substring(0, 20)}...` }} <br>
            </span>
          </span>
        </v-tooltip>
      </template>
      <template #[`item.actions`]="{item}">
        <v-btn
          color="primary"
          small
          text
          :disabled="loading"
          @click="onClickEdit(item.id)"
        >
          Edit
        </v-btn>
      </template>
    </v-data-table>
    <!-- Table -->

    <!-- Dialog Edit Catalogue Idea -->
    <dialog-edit-catalogue-idea
      v-model="dialogEditCatalogueIdeaVisible"
      :catalogue-idea="selectedCatalogueIdea"
      @finished="getCatalogueIdeas"
      @close="dialogEditCatalogueIdeaVisible = false"
    />
    <!-- Dialog Edit Catalogue Idea -->
  </div>
</template>

<script>
import { mdiInformationOutline, mdiMagnify } from '@mdi/js'
import DialogEditCatalogueIdea
  from '@/views/Admin/Components/CatalogueIdeas/Components/Dialogs/DialogEditCatalogueIdea.vue'
import _find from 'lodash/find'

export default {
  name: 'CatalogueIdeasTable',

  components: { DialogEditCatalogueIdea },

  data () {
    return {
      loading: false,
      catalogueIdeas: [],
      selectedCatalogueIdeaId: null,
      dialogEditCatalogueIdeaVisible: false,
      searchValue: '',
      icons: { mdiMagnify, mdiInformationOutline }
    }
  },

  computed: {
    searchResult () {
      if (this.searchValue !== '' && this.searchValue) {
        return this.catalogueIdeas.filter(item => item.name.toUpperCase().includes(this.searchValue.toUpperCase()))
      }

      return this.catalogueIdeas
    },

    selectedCatalogueIdea () {
      if (this.selectedCatalogueIdeaId) {
        return _find(this.catalogueIdeas, ['id', this.selectedCatalogueIdeaId])
      }

      return null
    },

    headers () {
      return [
        {
          text: 'Id',
          value: 'id',
          sortable: false
        },
        {
          text: 'Name',
          value: 'name',
          sortable: false
        },
        {
          text: 'Info Name',
          value: 'infoName',
          align: 'center',
          sortable: false
        },
        {
          text: 'Info Description',
          value: 'infoDescription',
          align: 'center',
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          divider: false,
          sortable: false
        }
      ]
    }
  },

  async mounted () {
    await this.getCatalogueIdeas()
  },

  methods: {
    async getCatalogueIdeas () {
      try {
        this.loading = true
        const response = await this.$http.get('admin/catalogue/ideas')
        this.catalogueIdeas = response.data.payload.catalogueIdeas
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },

    onClickEdit (catalogueIdeaId) {
      this.selectedCatalogueIdeaId = catalogueIdeaId
      this.dialogEditCatalogueIdeaVisible = true
    }
  }
}
</script>
