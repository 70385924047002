<template>
  <div>
    <v-tabs v-model="tab">
      <v-tabs-slider color="primary" />

      <v-tab
        v-for="locale in allowedLocales"
        :key="locale"
      >
        {{ locale }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="locale in allowedLocales"
        :key="locale"
      >
        <v-card :disabled="loading">
          <v-data-table
            :loading="loading"
            :items="templates"
            :headers="headers"
            hide-default-footer
            :options="{
              itemsPerPage: 1000
            }"
          >
            <template #[`item.actions`]="{item}">
              <v-btn
                color="primary"
                small
                text
                @click="onClickEdit(item.id)"
              >
                Edit
              </v-btn>
              <v-btn
                color="error"
                small
                text
                @click="onClickDelete(item.id)"
              >
                Delete
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <!-- Dialog Edit Idea Template -->
    <dialog-edit-idea-template
      v-model="dialogEditIdeaTemplateVisible"
      :idea-template="selectedIdeaTemplate"
      @finished="getIdeaTemplates"
      @close="dialogEditIdeaTemplateVisible = false"
    />
    <!-- Dialog Edit Idea Template -->
  </div>
</template>

<script>
import DialogEditIdeaTemplate from '@/views/Admin/Components/Templates/Components/Dialogs/DialogEditIdeaTemplate.vue'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import { allowedLocales } from '@/options/localeOptions'

export default {
  name: 'IdeaTemplatesList',

  components: { DialogEditIdeaTemplate },

  data () {
    return {
      allowedLocales,
      tab: null,
      selectedIdeaTemplateId: null,
      loading: false,
      ideaTemplates: [],
      dialogEditIdeaTemplateVisible: false
    }
  },

  computed: {
    selectedLocale () {
      return allowedLocales[this.tab]
    },

    templates () {
      return this.ideaTemplates.length ? _filter(this.ideaTemplates, ['lang', this.selectedLocale]) : []
    },

    selectedIdeaTemplate () {
      if (this.selectedIdeaTemplateId) {
        return _find(this.ideaTemplates, ['id', this.selectedIdeaTemplateId])
      }

      return null
    },

    headers () {
      return [
        {
          text: 'Id',
          value: 'id',
          sortable: false
        },
        {
          text: 'Idea',
          value: 'ideaName',
          sortable: false
        },
        {
          text: 'Category',
          value: 'categoryName',
          sortable: false
        },
        {
          text: 'Idea type',
          value: 'catalogueIdeaName',
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          divider: false,
          sortable: false
        }
      ]
    }
  },

  async mounted () {
    await this.getIdeaTemplates()
  },

  methods: {
    async getIdeaTemplates () {
      try {
        this.loading = true
        const response = await this.$http.get('admin/idea-templates')
        this.ideaTemplates = response.data.payload.ideaTemplates
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },

    async onClickDelete (ideaTemplateId) {
      if (confirm('Are you sure?')) {
        try {
          this.loading = true
          await this.$http.delete(`admin/idea-templates/${ideaTemplateId}`)
          await this.getIdeaTemplates()
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      }
    },

    onClickEdit (ideaTemplateId) {
      this.selectedIdeaTemplateId = ideaTemplateId
      this.dialogEditIdeaTemplateVisible = true
    }
  }
}
</script>
