<template>
  <div>
    <div class="d-flex align-center justify-content-between">
      <div>
        <h4>Catalogue Categories</h4>
      </div>
    </div>

    <!-- Catalogue Categories Table -->
    <catalogue-categories-table />
    <!-- Catalogue Categories Table -->
  </div>
</template>

<script>
import CatalogueCategoriesTable from '@/views/Admin/Components/CatalogueCategories/Components/CatalogueCategoriesTable.vue'

export default {
  name: 'CatalogueCategories',

  components: { CatalogueCategoriesTable }
}
</script>
