<template>
  <v-dialog
    :value="visible"
    max-width="45rem"
    persistent
    scrollable
    @input="close"
  >
    <v-card :loading="loading" :disabled="loading">
      <v-card-title>Edit Catalogue Idea</v-card-title>

      <v-divider class="w-100" />

      <v-card-text v-if="options" class="pt-2" style="height: 620px;">
        <div
          v-for="(option, key) in options"
          :key="key"
          class="mb-5"
        >
          <p class="mt-0 font-weight-bold">
            {{ key.toUpperCase() }}
          </p>
          <div class="mb-2">
            <v-text-field
              v-model="options[key].name"
              outlined
              dense
              hide-details="auto"
              label="Name"
              placeholder="Name"
            />
          </div>
          <div>
            <VueTrix v-model="options[key].description" placeholder="Description" />
          </div>
        </div>
      </v-card-text>

      <v-divider class="w-100" />

      <v-card-actions class="justify-end">
        <v-btn
          text
          color="error"
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          :elevation="0"
          :disabled="isSaveBtnDisabled"
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VueTrix from 'vue-trix'
import _cloneDeep from 'lodash/cloneDeep'
import _values from 'lodash/values'

export default {
  name: 'DialogEditCatalogueIdea',

  components: {
    VueTrix
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    catalogueIdea: {
      validator: value => typeof value === 'object' || value === null,
      required: true
    }
  },

  data () {
    return {
      loading: false,
      options: null
    }
  },

  computed: {
    visible: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },

    isSaveBtnDisabled () {
      const names = _values(this.options)
        .reduce((a, item) => {
          return a.concat(item.name)
        }, [])

      const descriptions = _values(this.options)
        .reduce((a, item) => {
          return a.concat(item.description)
        }, [])

      return names.concat(descriptions).some(value => value === '')
    }
  },

  watch: {
    visible (value) {
      if (value) {
        this.setCatalogueIdeaOptions()
      }
    }
  },

  methods: {
    async save () {
      try {
        this.loading = true
        await this.$http.put(`admin/catalogue/ideas/${this.catalogueIdea.id}`, { data: this.options })
        this.$emit('finished')
        this.close()
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },

    setCatalogueIdeaOptions () {
      this.options = _cloneDeep(this.catalogueIdea.options)
    },

    close () {
      this.$emit('close')
    }
  }
}
</script>
