export default [
  {
    value: 'ideaExamples',
    label: 'Idea'
  },
  {
    value: 'productExamples',
    label: 'Product'
  },
  {
    value: 'promotionExamples',
    label: 'Promotion'
  },
  {
    value: 'distributionExamples',
    label: 'Distribution'
  },
  {
    value: 'partnerExamples',
    label: 'Partner'
  },
  {
    value: 'marketExamples',
    label: 'Market'
  },
  {
    value: 'customersExamples',
    label: 'Customers'
  },
  {
    value: 'competitorsExamples',
    label: 'Competitors'
  },
  {
    value: 'swotAnalysisExamples',
    label: 'Swot Analysis'
  },
  {
    value: 'marketPotentialExamples',
    label: 'Market Potential'
  },
  {
    value: 'startupCostsExamples',
    label: 'Startup Costs'
  },
  {
    value: 'financingExamples',
    label: 'Financing'
  },
  {
    value: 'managementTeamExamples',
    label: 'Management Team'
  },
  {
    value: 'employeesAndPayrollExamples',
    label: 'Staff And Payroll'
  },
  {
    value: 'ownershipExamples',
    label: 'Ownership'
  },
  {
    value: 'revenueStreamsExamples',
    label: 'Revenue Streams'
  },
  {
    value: 'directCostsExamples',
    label: 'Direct Costs'
  },
  {
    value: 'marketingBudgetExamples',
    label: 'Marketing Budget'
  },
  {
    value: 'otherOverheadsExamples',
    label: 'Other Overheads'
  },
  {
    value: 'profitAndCashFlowExamples',
    label: 'Profit And Cash Flow'
  },
  {
    value: 'ideaScoreExamples',
    label: 'Idea Score'
  }
]
