<template>
  <v-card>
    <v-card-title>
      Redeemed coupons
    </v-card-title>
    <v-divider class="ma-0" />

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items.sync="tableData"
      hide-default-footer
    />
    <v-divider v-if="showPagination" class="mt-0" />
    <v-card-text v-if="showPagination">
      <v-pagination
        v-model="params.page"
        :disabled="loading"
        :length.sync="pagination.totalPages"
      />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'CouponsList',

  data () {
    return {
      loading: false,
      params: {
        page: 1
      },
      tableData: [],
      pagination: []
    }
  },

  computed: {
    showPagination () {
      return this.pagination && this.pagination.totalPages > 0
    },

    headers () {
      return [
        {
          text: 'Coupon code',
          value: 'code',
          divider: false,
          align: 'center',
          sortable: false,
          filterable: false,
          width: '50%'
        },
        {
          text: 'Email',
          value: 'email',
          divider: false,
          align: 'center',
          sortable: false,
          filterable: false,
          width: '50%'
        }
      ]
    }
  },

  watch: {
    'params.page': {
      handler () {
        this.getCoupons()
      }
    }
  },

  mounted () {
    this.getCoupons()
  },

  methods: {
    async getCoupons () {
      this.loading = true

      this.$http.get('admin/coupons', { params: this.params })
        .then(response => {
          const { data, pagination } = response.data.payload

          this.tableData = data
          this.pagination = pagination
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
