<template>
  <div>
    <v-tabs v-model="tab">
      <v-tabs-slider color="primary" />

      <v-tab
        v-for="locale in allowedLocales"
        :key="locale"
      >
        {{ locale }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="locale in allowedLocales"
        :key="locale"
      >
        <v-card :disabled="loading">
          <!-- Table -->
          <v-data-table
            :loading="loading"
            :items="items"
            :headers="headers"
          >
            <template #[`item.title`]="{item}">
              {{ item.title ? item.title : '-' }}
            </template>
            <template #[`item.type`]="{item}">
              {{ getTypeName(item.type).label }}
            </template>

            <template #[`item.catalogueCategoryName`]="{item}">
              {{ item.catalogueCategoryName ? item.catalogueCategoryName : '-' }}
            </template>

            <template #[`item.catalogueIdeaName`]="{item}">
              {{ item.catalogueIdeaName ? item.catalogueIdeaName : '-' }}
            </template>

            <template #[`item.actions`]="{item}">
              <v-btn
                color="primary"
                small
                text
                :disabled="loading"
                @click="onClickEdit(item.id)"
              >
                Edit
              </v-btn>
              <v-btn
                color="warning"
                small
                text
                :disabled="loading"
                @click="onClickCopy(item.id)"
              >
                Copy
              </v-btn>
              <v-btn
                color="error"
                small
                text
                :disabled="loading"
                @click="onClickDelete(item.id)"
              >
                Delete
              </v-btn>
            </template>
          </v-data-table>
          <!-- /Table -->
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <!-- Dialog Edit Tip -->
    <dialog-add-edit-tip
      v-model="dialogEditTipVisible"
      :tip="selectedTip"
      :is-copy-mode="isCopyMode"
      @finished="getTips"
      @close="onCloseDialog"
    />
    <!-- /Dialog Edit Tip -->
  </div>
</template>

<script>
import DialogAddEditTip from '@/views/Admin/Components/Tips/Components/Dialogs/DialogAddEditTip.vue'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import { allowedLocales } from '@/options/localeOptions'
import tipTypes from '@/data/tipTypes'

export default {
  name: 'TipsTable',
  components: { DialogAddEditTip },

  data () {
    return {
      allowedLocales,
      tab: null,
      loading: false,
      tips: [],
      selectedTipId: null,
      dialogEditTipVisible: false,
      isCopyMode: false,
      tipTypes
    }
  },

  computed: {
    selectedLocale () {
      return allowedLocales[this.tab]
    },

    items () {
      return this.tips.length ? _filter(this.tips, ['lang', this.selectedLocale]) : []
    },

    selectedTip () {
      if (this.selectedTipId) {
        return _find(this.tips, ['id', this.selectedTipId])
      }

      return {}
    },

    headers () {
      return [
        {
          text: 'Id',
          value: 'id',
          sortable: false
        },
        {
          text: 'Title',
          value: 'title',
          sortable: false
        },
        {
          text: 'Step',
          value: 'type',
          sortable: false
        },
        {
          text: 'Category',
          value: 'catalogueCategoryName',
          sortable: false
        },
        {
          text: 'Idea',
          value: 'catalogueIdeaName',
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          divider: false,
          sortable: false
        }
      ]
    }
  },

  async mounted () {
    await this.getTips()
  },

  methods: {
    async getTips () {
      try {
        this.loading = true
        const response = await this.$http.get('admin/tips')
        this.tips = response.data.payload.tips
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },

    getTypeName (type) {
      return _find(this.tipTypes, ['value', type])
    },

    onClickEdit (tipId) {
      this.selectedTipId = tipId
      this.dialogEditTipVisible = true
    },

    onClickCopy (tipId) {
      this.selectedTipId = tipId
      this.dialogEditTipVisible = true
      this.isCopyMode = true
    },

    async onClickDelete (tipId) {
      if (confirm('Are you sure?')) {
        try {
          this.loading = true
          await this.$http.delete(`admin/tips/${tipId}`)
          await this.getTips()
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      }
    },

    onCloseDialog () {
      this.dialogEditTipVisible = false
      this.selectedTipId = null
      this.isCopyMode = false
    }
  }
}
</script>
