<template>
  <div>
    <!-- Table -->
    <v-data-table
      :loading="loading"
      :items="catalogueCategories"
      :headers="headers"
      hide-default-footer
      :options="{
        itemsPerPage: 1000
      }"
    >
      <template #[`item.info`]="{item}">
        <!-- Tooltip -->
        <v-tooltip top :disabled="loading">
          <template #activator="{ on, attrs }">
            <v-icon
              color="primary"
              dark
              v-bind="attrs"
              class="cursor-pointer"
              v-on="on"
            >
              {{ icons.mdiInformationOutline }}
            </v-icon>
          </template>
          <span>
            <span
              v-for="(option, key) in item.options"
              :key="`${key}-${option.name}-${Math.random()}`"
            >
              {{ key.toUpperCase() }} - {{ option.name }} <br>
            </span>
          </span>
        </v-tooltip>
        <!-- Tooltip -->
      </template>

      <template #[`item.actions`]="{item}">
        <v-btn
          color="primary"
          small
          text
          :disabled="loading"
          @click="onClickEdit(item.id)"
        >
          Edit
        </v-btn>
      </template>
    </v-data-table>
    <!-- Table -->

    <!-- Dialog Edit Catalogue Category -->
    <dialog-edit-catalogue-category
      v-model="dialogEditCatalogueCategoryVisible"
      :catalogue-category="selectedCatalogueCategory"
      @finished="getCatalogueCategories"
      @close="dialogEditCatalogueCategoryVisible = false"
    />
    <!-- Dialog Edit Catalogue Category -->
  </div>
</template>

<script>
import DialogEditCatalogueCategory
  from '@/views/Admin/Components/CatalogueCategories/Components/Dialogs/DialogEditCatalogueCategory.vue'
import _find from 'lodash/find'
import { mdiInformationOutline } from '@mdi/js'

export default {
  name: 'CatalogueCategoriesTable',

  components: { DialogEditCatalogueCategory },

  data () {
    return {
      loading: false,
      catalogueCategories: [],
      selectedCatalogueCategoryId: null,
      dialogEditCatalogueCategoryVisible: false,
      icons: { mdiInformationOutline }
    }
  },

  computed: {
    selectedCatalogueCategory () {
      if (this.selectedCatalogueCategoryId) {
        return _find(this.catalogueCategories, ['id', this.selectedCatalogueCategoryId])
      }

      return null
    },

    headers () {
      return [
        {
          text: 'Id',
          value: 'id',
          sortable: false
        },
        {
          text: 'Name',
          value: 'name',
          sortable: false
        },
        {
          text: 'Position',
          value: 'position',
          sortable: false
        },
        {
          text: 'Info',
          value: 'info',
          align: 'center',
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          divider: false,
          sortable: false
        }
      ]
    }
  },

  async mounted () {
    await this.getCatalogueCategories()
  },

  methods: {
    async getCatalogueCategories () {
      try {
        this.loading = true
        const response = await this.$http.get('admin/catalogue/categories')
        this.catalogueCategories = response.data.payload.catalogueCategories
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },

    onClickEdit (catalogueCategoryId) {
      this.selectedCatalogueCategoryId = catalogueCategoryId
      this.dialogEditCatalogueCategoryVisible = true
    }
  }
}
</script>
