export default [
  {
    value: 49,
    label: 'AppSumo Founder Lifetime 1/1'
  },
  {
    value: 24,
    label: 'AppSumo Founder Lifetime 3/3'
  },
  {
    value: 33,
    label: 'AppSumo Founder Lifetime 10/10'
  },
  {
    value: 25,
    label: 'AppSumo Founder Lifetime 30/30'
  },
  {
    value: 1,
    label: 'Dreamer Monthly 1/1'
  },
  {
    value: 2,
    label: 'Dreamer Monthly 3/3'
  },
  {
    value: 3,
    label: 'Dreamer Monthly 10/10'
  },
  {
    value: 4,
    label: 'Dreamer Monthly 30/30'
  },
  {
    value: 5,
    label: 'Dreamer Monthly 100/100'
  },
  {
    value: 6,
    label: 'Dreamer Yearly 1/1'
  },
  {
    value: 7,
    label: 'Dreamer Yearly 3/3'
  },
  {
    value: 8,
    label: 'Dreamer Yearly 10/10'
  },
  {
    value: 9,
    label: 'Dreamer Yearly 30/30'
  },
  {
    value: 10,
    label: 'Dreamer Yearly 100/100'
  },
  {
    value: 22,
    label: 'Dreamer Lifetime 1/1'
  },
  {
    value: 26,
    label: 'Dreamer Lifetime 3/3'
  },
  {
    value: 27,
    label: 'Dreamer Lifetime 10/10'
  },
  {
    value: 28,
    label: 'Dreamer Lifetime 30/30'
  },
  {
    value: 29,
    label: 'Dreamer Lifetime 100/100'
  },
  {
    value: 11,
    label: 'Founder Monthly 1/1'
  },
  {
    value: 12,
    label: 'Founder Monthly 3/3'
  },
  {
    value: 13,
    label: 'Founder Monthly 10/10'
  },
  {
    value: 14,
    label: 'Founder Monthly 30/30'
  },
  {
    value: 15,
    label: 'Founder Monthly 100/100'
  },
  {
    value: 16,
    label: 'Founder Yearly 1/1'
  },
  {
    value: 17,
    label: 'Founder Yearly 3/3'
  },
  {
    value: 18,
    label: 'Founder Yearly 10/10'
  },
  {
    value: 19,
    label: 'Founder Yearly 30/30'
  },
  {
    value: 20,
    label: 'Founder Yearly 100/100'
  },
  {
    value: 30,
    label: 'Founder Lifetime 1/1'
  },
  {
    value: 21,
    label: 'Founder Lifetime 3/3'
  },
  {
    value: 31,
    label: 'Founder Lifetime 10/10'
  },
  {
    value: 23,
    label: 'Founder Lifetime 30/30'
  },
  {
    value: 32,
    label: 'Founder Lifetime 100/100'
  },
  {
    value: 34,
    label: 'Team Pro Monthly 1/1'
  },
  {
    value: 35,
    label: 'Team Pro Monthly 3/3'
  },
  {
    value: 36,
    label: 'Team Pro Monthly 10/10'
  },
  {
    value: 37,
    label: 'Team Pro Monthly 30/30'
  },
  {
    value: 38,
    label: 'Team Pro Monthly 100/100'
  },
  {
    value: 39,
    label: 'Team Pro Yearly 1/1'
  },
  {
    value: 40,
    label: 'Team Pro Yearly 3/3'
  },
  {
    value: 41,
    label: 'Team Pro Yearly 10/10'
  },
  {
    value: 42,
    label: 'Team Pro Yearly 30/30'
  },
  {
    value: 43,
    label: 'Team Pro Yearly 100/100'
  },
  {
    value: 44,
    label: 'Team Pro Lifetime 1/1'
  },
  {
    value: 45,
    label: 'Team Pro Lifetime 3/3'
  },
  {
    value: 46,
    label: 'Team Pro Lifetime 10/10'
  },
  {
    value: 47,
    label: 'Team Pro Lifetime 30/30'
  },
  {
    value: 48,
    label: 'Team Pro Lifetime 100/100'
  }
]
